import { useState, useEffect, createContext, useContext } from "react";
import isToday from "date-fns/isToday";

import { Atendimento } from "../../api/api";
import { useAuth } from "../Auth/AuthContext";
import { beginAndEndOfNextWeek } from "../../components/beginAndEndOfNextWeek";

const AgendamentoContext = createContext({});
const [monday, friday] = beginAndEndOfNextWeek();

export const statusCancelamentoAgendamento = {
  pacienteNaoCompareceu: "Paciente não compareceu",
  erroCadastral: "Erro cadastral",
  naoRealizado: "Não realizado",
};

export const statusAgendamento = {
  ...statusCancelamentoAgendamento,
  emAberto: "Em aberto",
  realizado: "Realizado",
};

export const statusEmAtrasoAgendamento = "Em atraso";

export const useAgendamento = () => useContext(AgendamentoContext);

export function AgendamentoProvider({ children }) {
  const { user } = useAuth();
  const [hoje, setHoje] = useState([]);
  const [semana, setSemana] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    setIsLoading(true);
    const { data } = await Atendimento(user.jwt).get({
      dataAgendamento_gte: monday,
      dataAgendamento_lte: friday,
      status_contains: statusAgendamento.emAberto,
      _sort: "dataAgendamento:asc",
    });

    setSemana(data);
    setHoje(data.filter((a) => isToday(new Date(a.dataAgendamento))));
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const value = {
    agendamentoSemana: semana,
    agendamentoHoje: hoje,
    isLoading,
  };

  return (
    <AgendamentoContext.Provider value={value}>
      {children}
    </AgendamentoContext.Provider>
  );
}
