import { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "../Auth/AuthContext";
import { useMessage } from "../Message/MessageContext";
import { Pessoa } from "../../api/api";
import localStorage from "../../utils/localStorage";
import { usePessoaDocumento } from "../PessoaDocumento/PessoaDocumentoContext";
import { PessoaDto } from "./dtos/Pessoa.dto";

export * from "./dtos/Pessoa.dto";

export const PessoaContext = createContext({});

export function usePessoa() {
  return useContext(PessoaContext);
}

const { getSession, setSession } = localStorage("CECP");

export function PessoaProvider({ children }) {
  const { user } = useAuth();
  const { setSuccess, setError } = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { saveDocumentos, getDocumentos, clearDocumentos } =
    usePessoaDocumento();
  const [pessoa, setPessoa] = useState(getSession() || PessoaDto);

  useEffect(() => {
    setSession(pessoa);
  }, [pessoa]);

  useEffect(() => {
    if (pessoa.id) {
      getDocumentos(pessoa.id);
      return;
    }

    clearDocumentos();
  }, [pessoa.id]);

  const update = async (p) => {
    setIsLoading(true);
    try {
      const { data } = await Pessoa(user.jwt).update(p.id, {
        ...p,
        bairro: p.bairro?.id,
      });
      await saveDocumentos(p.id);
      setPessoa(data);
      setSuccess("Paciente atualizado com sucesso");
      return data;
    } catch (e) {
      setError("Problema ao tentar atualizar paciente");
    } finally {
      setIsLoading(false);
    }
  };

  const add = async (p) => {
    setIsLoading(true);
    try {
      const { data } = await Pessoa(user.jwt).add({
        ...p,
        bairro: p.bairro?.id,
      });
      await saveDocumentos(data.id);
      setPessoa(data);
      setSuccess("Paciente adicionado com sucesso");
      return data;
    } catch (e) {
      setError("Problema ao tentar cadastrar paciente");
    } finally {
      setIsLoading(false);
    }
  };

  const searchPessoa = async (filter = {}) => {
    setIsLoading(true);
    try {
      const { data } = await Pessoa(user.jwt).get({
        ...filter,
        _sort: "nome:asc",
      });
      return data;
    } catch (e) {
      setError("Problema ao tentar buscar pessoa");
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    isLoading,
    pessoa,
    setPessoa,
    searchPessoa,
    savePessoa: (p) => (p.id ? update(p) : add(p)),
  };

  return (
    <PessoaContext.Provider value={value}>{children}</PessoaContext.Provider>
  );
}
