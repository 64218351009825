import React, { useState, useEffect } from "react";
import clsx from "clsx";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AgendamentoProvider } from "../Agendamento/AgendamentoContext";
import { PessoaAgendamentoProvider } from "../PessoaAgendamento/PessoaAgendamentoContext";
import { PessoaProvider } from "../Pessoa/PessoaContext";
import { PessoaDocumentoProvider } from "../PessoaDocumento/PessoaDocumentoContext";
import { PessoaBuscaDialog } from "./PessoaBuscaDialog";
import { PessoaAgenda } from "../PessoaAgenda/PessoaAgenda";

import { AppBar } from "./AppBar";
import { Menu } from "./Menu";
import StyledRoot, { classes } from "./styles";
import { DesligamentoProvider } from "../PessoaDesligamento/DesligamentoContext";
import { HeaderPrint } from "./HeaderPrint";

export default function Layout({ children }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(!isMedium);
  }, [isMedium]);

  return (
    <AgendamentoProvider>
      <PessoaDocumentoProvider>
        <PessoaProvider>
          <PessoaAgendamentoProvider>
            <DesligamentoProvider>
              <HeaderPrint />
              <StyledRoot className={classes.container}>
                <CssBaseline />
                <AppBar toggleDrawer={toggleDrawer} open={open} />
                <Drawer
                  variant={!isSmall ? "permanent" : undefined}
                  classes={{
                    paper: clsx(
                      classes.drawerPaper,
                      !open && classes.drawerPaperClose
                    ),
                  }}
                  open={open}
                >
                  <div className={classes.toolbarIcon}>
                    <IconButton onClick={toggleDrawer}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </div>
                  <Menu />
                </Drawer>
                <Box component="main" className={classes.body}>
                  <div className={classes.appBarSpacer} />
                  <PessoaBuscaDialog />
                  <Grid container spacing={3} style={{ marginTop: 20 }}>
                    <Container component="main" maxWidth="md">
                      {children}
                    </Container>
                  </Grid>
                </Box>
                <PessoaAgenda />
              </StyledRoot>
            </DesligamentoProvider>
          </PessoaAgendamentoProvider>
        </PessoaProvider>
      </PessoaDocumentoProvider>
    </AgendamentoProvider>
  );
}
