import { useEffect, useState } from "react";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";

import { usePessoa } from "../Pessoa/PessoaContext";
import { LoadingButton } from "../../components/Loading";
import { statusCancelamentoAgendamento } from "../Agendamento/AgendamentoContext";

import { usePessoaAgendamento } from "./PessoaAgendamentoContext";

const motivos = Object.values(statusCancelamentoAgendamento);

export const Cancelar = ({ agendamento, open, onClose }) => {
  const { updateAgendamento, isLoading } = usePessoaAgendamento();
  const [updatedItem, setUpdatedItem] = useState(agendamento);
  const { pessoa } = usePessoa();

  useEffect(() => {
    setUpdatedItem(agendamento);
  }, [agendamento]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await updateAgendamento(updatedItem);
    onClose();
  };

  if (!updatedItem) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Cancelar agendamento</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box mb={2}>
            <Alert severity="warning">
              Deseja realmente cancelar o agendamento de{" "}
              <strong>{pessoa.nome}</strong> para o dia{" "}
              <strong>
                {format(
                  new Date(updatedItem.dataAgendamento),
                  "dd/MM/yyyy H:mm"
                )}{" "}
              </strong>
              com o setor de{" "}
              <strong>{updatedItem.setorAtendimento.nome}</strong>?
              <Box mt={1}>
                <strong>Atenção, esta ação não pode ser revertida!</strong>
              </Box>
            </Alert>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={motivos}
                value={
                  motivos.includes(updatedItem.status) ? updatedItem.status : ""
                }
                onChange={(e, status) =>
                  setUpdatedItem({ ...updatedItem, status })
                }
                fullWidth
                autoFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Motivo"
                    variant="outlined"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={(e) =>
                  setUpdatedItem({ ...updatedItem, descricao: e.target.value })
                }
                value={updatedItem.descricao}
                label="Obs."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} type="submit" color="primary">
            Confirmar
          </LoadingButton>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
