import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import TodayIcon from "@material-ui/icons/Today";
import { Tooltip, Typography } from "@mui/material";
import { format, isSameDay, eachDayOfInterval, isThisWeek } from "date-fns";
import { ptBR } from "date-fns/locale";

import { usePessoa } from "../Pessoa/PessoaContext";
import { usePessoaDocumento } from "../PessoaDocumento/PessoaDocumentoContext";
import { usePessoaAgendamento } from "../PessoaAgendamento/PessoaAgendamentoContext";

import { beginAndEndOfNextWeek } from "../../components/beginAndEndOfNextWeek";
import StyledRoot, { classes } from "./styles";
import { statusAgendamento } from "../Agendamento/AgendamentoContext";

const [monday, friday] = beginAndEndOfNextWeek();

export const PessoaAgenda = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { pessoa } = usePessoa();
  const { listAgendamento } = usePessoaAgendamento();
  const { avatar, showDocumento } = usePessoaDocumento();

  const setoresAtendimento = listAgendamento.reduce((acc, a) => {
    if (
      new Date() > new Date(a.dataAgendamento) &&
      a.status === statusAgendamento.emAberto
    ) {
      return acc;
    }

    acc[a.setorAtendimento.nome] = {
      ...a.setorAtendimento,
      count: (acc[a.setorAtendimento.nome]?.count || 0) + 1,
      items: [...(acc[a.setorAtendimento.nome]?.items || []), a],
    };

    return acc;
  }, {});

  const agendamentosSemana = listAgendamento.filter((a) =>
    isThisWeek(new Date(a.dataAgendamento))
  );

  const daysWeek = eachDayOfInterval({ start: monday, end: friday });

  if (!pessoa.id || pessoa.desligada) return null;

  return (
    <StyledRoot>
      <Drawer
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            !isSmall && classes.positionRelative,
            "no-print"
          ),
        }}
        variant={!isSmall ? "permanent" : undefined}
        anchor="right"
        open={open}
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Avatar
            alt="Foto paciente"
            className={classes.avatar}
            src={showDocumento(avatar?.documento[0])}
          >
            {pessoa.nome.toUpperCase().substr(0, 2)}
          </Avatar>
          <Typography variant="h6">{pessoa.nome}</Typography>
          <Typography variant="subtitle2">
            {format(new Date(pessoa.nascimento), "dd/MM/yyyy")}
          </Typography>
          <Typography variant="body2">{pessoa.RG}</Typography>
          <Typography variant="body2">
            {pessoa.numeroSusReabilitando}
          </Typography>
          <Typography>{pessoa.cid}</Typography>
          {daysWeek.map((day, dayIndex) => (
            <List key={dayIndex}>
              <ListItem>
                <ListItemText
                  key={dayIndex}
                  primary={format(day, "EEEE", { locale: ptBR })}
                  secondary={format(day, "EEEEEE dd", { locale: ptBR })}
                />
                <div>
                  {agendamentosSemana
                    .filter((i) => isSameDay(new Date(i.dataAgendamento), day))
                    .map((a) => (
                      <span
                        className={classes.chip}
                        style={{ backgroundColor: a.setorAtendimento.color }}
                      />
                    ))}
                </div>
              </ListItem>
              <Divider component="li" />
            </List>
          ))}

          <div className={classes.setoresAtendimento}>
            {agendamentosSemana.length > 0 &&
              Object.entries(setoresAtendimento).map(([_, a]) => (
                <div key={a.id}>
                  <span
                    className={classes.chip}
                    style={{ backgroundColor: a.color }}
                  />
                  <Tooltip
                    title={
                      <>
                        <b>{a.count}</b> agendamentos futuros para o setor de{" "}
                        <b>{a.nome}</b>
                      </>
                    }
                  >
                    <Typography variant="caption">
                      {a.nome}: <b>{a.count}</b>
                    </Typography>
                  </Tooltip>
                </div>
              ))}
          </div>
        </div>
      </Drawer>

      {isSmall ? (
        <IconButton
          color="primary"
          className={classes.button + " no-print"}
          onClick={() => setOpen(true)}
        >
          <TodayIcon />
        </IconButton>
      ) : (
        <Button
          color="primary"
          className={classes.button + " no-print"}
          endIcon={<TodayIcon />}
          onClick={() => setOpen(true)}
        >
          Agendamento
        </Button>
      )}
    </StyledRoot>
  );
};
