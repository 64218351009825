import MuiCard from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Box } from "@mui/material";

export const Card = ({
  title,
  classes,
  agendamentos = [],
  isLoading,
  dataFormat,
}) => (
  <MuiCard className={classes.root}>
    <CardContent>
      <Typography variant="h6" component="h4">
        {title}
      </Typography>
      <List sx={{ overflow: "auto", maxHeight: 300 }}>
        {isLoading && <LinearProgress />}

        {!isLoading &&
          agendamentos.map((a, i) => (
            <ListItem key={i} disableGutters>
              <ListItemText
                primary={a.pessoa.nome}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {format(new Date(a.dataAgendamento), dataFormat, {
                        locale: ptBR,
                      })}
                    </Typography>
                    {a.profissional?.nome && ` com ${a.profissional.nome}`}
                    <Box marginTop={1} display="flex">
                      <span
                        className={classes.chip}
                        style={{ backgroundColor: a.setorAtendimento.color }}
                      />
                      <Typography variant="caption">
                        {a.setorAtendimento.nome}
                      </Typography>
                    </Box>
                  </>
                }
              />
            </ListItem>
          ))}

        {!isLoading && agendamentos.length === 0 && (
          <ListItem disableGutters>
            <ListItemText
              primary={
                <Alert variant="outlined" severity="warning">
                  Nenhum agendamento registrado
                </Alert>
              }
            />
          </ListItem>
        )}
      </List>
    </CardContent>
    <CardActions>
      <Box marginLeft={1}>
        <Button color="primary" component={Link} to="/agendamento" size="small">
          Novo Agendamento
        </Button>
      </Box>
    </CardActions>
  </MuiCard>
);
